import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FormsModule } from '@angular/forms';
import { FCM } from '@ionic-native/fcm/ngx';
import { Firebase } from '@ionic-native/firebase/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ComponentsModule } from './components/components.module';
import {StorereturnExchangepoliciesComponent} from './storereturn-exchangepolicies/storereturn-exchangepolicies.component';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/File/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileTransfer} from '@ionic-native/file-transfer/ngx';
// Import the library
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { EmailFormModalPage } from './email-form-modal/email-form-modal.page'//added by priyanka for guest login
import { ReactiveFormsModule } from '@angular/forms'; //added by priyanka for guest login
import { ModalSellUnitComponent } from './modal-sell-unit/modal-sell-unit.component';
// import {  FormGroup } from '@angular/forms';

@NgModule({
  declarations: [AppComponent,
    ModalSellUnitComponent,
    EmailFormModalPage,
    // PrivacyPolicyComponent,
    StorereturnExchangepoliciesComponent, 
    // HomePage
  ],
  entryComponents: [ModalSellUnitComponent,EmailFormModalPage],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,NgxImgZoomModule,
    HttpClientModule,
    FormsModule,
    // FormGroup,
    ComponentsModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeStorage,
    SpinnerDialog,
    UniqueDeviceID,
    BarcodeScanner,
    FCM,
    FileTransfer,
    Firebase,
    LocalNotifications,
    FilePath,
    Camera,
    File,
    Base64,
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],exports:[]
})
export class AppModule { }
