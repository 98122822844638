import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {StorereturnExchangepoliciesComponent} from "./storereturn-exchangepolicies/storereturn-exchangepolicies.component"

const routes: Routes = [
  { path: "", redirectTo: "my-splash", pathMatch: "full",data:{preload: true} },
  {
    path: "my-splash",
    loadChildren: "./my-splash/my-splash.module#MySplashPageModule",data:{preload: true}
  },
  { path: "home", loadChildren: "./home/home.module#HomePageModule", data: {preload: true}},
  { path: "list", loadChildren: "./list/list.module#ListPageModule" , data: {preload: true}},
  { path: "login", loadChildren: "./login/login.module#LoginPageModule", data: {preload: true} },
  {
    path: "weekly-special",
    loadChildren:
      "./weekly-special/weekly-special.module#WeeklySpecialPageModule", data: {preload: true}
  },
  {
    path: "genrate-password",
    loadChildren:
      "./genrate-password/genrate-password.module#GenratePasswordPageModule", data: {preload: true}
  },
  {
    path: "customer-services",
    loadChildren:"./customer-services/customer-services.module#CustomerServicesPageModule",data:{preload: true}
  },
  {path: 'storereturn-exchangepolicies' ,data:{preload: true}, component: StorereturnExchangepoliciesComponent},
  {
    path: "category",
    loadChildren: "./category/category.module#CategoryPageModule",data:{preload: true}
  },
  {
    path: "subcategory",
    loadChildren: "./subcategory/subcategory.module#SubcategoryPageModule",data:{preload: true}
  },
  {
    path: "bestseller",
    loadChildren: "./bestseller/bestseller.module#BestsellerPageModule",data:{preload: true}
  },


  {
    path: "product",
    loadChildren: "./product/product.module#ProductPageModule",data:{preload: true}
  },
  {
    path: "productdetail",
    loadChildren:
      "./productdetail/productdetail.module#ProductdetailPageModule",data:{preload: true}
  },
  { path: "card", loadChildren: "./card/card.module#CardPageModule" ,data:{preload: true}},
  {
    path: "own-card",
    loadChildren: "./own-card/own-card.module#OwnCardPageModule",data:{preload: true}
  },
  {
    path: "mybarcode",
    loadChildren: "./mybarcode/mybarcode.module#MybarcodePageModule",data:{preload: true}
  },
  { path: "search", loadChildren: "./search/search.module#SearchPageModule",data:{preload: true} },
  {
    path: "search-products",
    loadChildren:
      "./search-products/search-products.module#SearchProductsPageModule",data:{preload: true}
  },
  {
    path: "currencyselector",
    loadChildren:
      "./currencyselector/currencyselector.module#CurrencyselectorPageModule",data:{preload: true}
  },
  { path: "cart", loadChildren: "./cart/cart.module#CartPageModule",data:{preload: true} },
  {
    path: "registration",
    loadChildren: "./registration/registration.module#RegistrationPageModule",data:{preload: true}
  },
  {
    path: "notification",
    loadChildren: "./notification/notification.module#NotificationPageModule",data:{preload: true}
  },
  {
    path: "orderhistory",
    loadChildren: "./orderhistory/orderhistory.module#OrderhistoryPageModule",data:{preload: true}
  },
  {
    path: "order-history-detail",
    loadChildren:
      "./order-history-detail/order-history-detail.module#OrderHistoryDetailPageModule",data:{preload: true}
  },
  {
    path: "disclaimer",
    loadChildren: "./disclaimer/disclaimer.module#DisclaimerPageModule",data:{preload: true}
  },
  {
    path: "wishlist",
    loadChildren: "./wishlist/wishlist.module#WishlistPageModule",data:{preload: true}
  },
  {
    path: "feedback",
    loadChildren: "./feedback/feedback.module#FeedbackPageModule",data:{preload: true}
  },
  {
    path: "giftcard",
    loadChildren: "./giftcard/giftcard.module#GiftcardPageModule",data:{preload: true}
  },
  {
    path: "giftcarddetail",
    loadChildren:
      "./giftcarddetail/giftcarddetail.module#GiftcarddetailPageModule",data:{preload: true}
  },
  {
    path: "homewebsite",
    loadChildren: "./homewebsite/homewebsite.module#HomewebsitePageModule",data:{preload: true}
  },
  {
    path: "product-zoom",
    loadChildren: "./product-zoom/product-zoom.module#ProductZoomPageModule",data:{preload: true}
  },
  { path: "demo", loadChildren: "./demo/demo.module#DemoPageModule",data:{preload: true} },
  {
    path: "bestseller",
    loadChildren: "./bestseller/bestseller.module#BestsellerPageModule",data:{preload: true}
  },  { path: 'email-form-modal', loadChildren: './email-form-modal/email-form-modal.module#EmailFormModalPageModule' },

 

  

];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules ,useHash: true })
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {}
