import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { WeeklyService } from "src/app/services/weekly.service";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { AlertController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { S3imagesService } from '../../services/s3images.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  newUrl = this.images3Service.s3url;
  @Input("cartCount") cartCount: any;
  cartcount: number;
  logincheck: any;
  isGuest: string;
  searchPlaceHolder: String = "Search";
  cardno: string;
  password: string;
  userName: any;
  username: any;
  hello: any;
  loginUsername: any;
  showlogout: any;
  loginId: any;
  header: any;
  cardCountRes: any;

  constructor(
    public storage: Storage,
    private router: Router,
    private barcodeScanner: BarcodeScanner,
    public movieService: WeeklyService,
    public alertController: AlertController,private images3Service :S3imagesService
  ) {}

  async callNecessaryApi() {
    this.isGuest = this.movieService.getIsGuest();
    this.header = this.movieService.getStorageHeader();
    this.cardno = this.movieService.getStorageCardNo();
    this.password= this.movieService.getStoragePassword();

    // if (this.userName) {
    //   this.movieService.setCardCount("0");
    // }
    // if (!this.userName) {
    //   this.movieService.setCardCount("0");
    this.storage.get('loginUsername').then(val => {
      this.logincheck = val;
      console.log("checkdaatadatad",this.logincheck)
    if (this.logincheck == 'Guest') { 
      // this.hideLoader();
       // For guest users, get the cart items from localStorage
        let cartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
        this.cartcount = cartItems.length.toString(); // Convert count to string if necessary
        this.movieService.setCardCount( this.cartcount);
      // this.movieService.setCardCount('0');
    } else if (this.isGuest == 'false') {
    // } else {
      this.cardCountRes = this.movieService.getAllItemInCart(
        this.cardno,
        this.header,
        this.password
      );
      this.cardCountRes.subscribe((mdata) => {
        if (mdata.status == "true") {
          this.movieService.setCardCount(mdata.value.length);
          this.cartcount = mdata.value.length;
        } else {
          this.movieService.setCardCount(0);
          this.cartcount = 0;
        }
      });
    }
  })
  }

  ngOnChanges() {
    this.callNecessaryApi();
  }

  logout() {
    alert("This is logout");
    this.startMySearchThread().then((truthy) => {
      this.router.navigate(["logout"]);
    });
  }

  async ngOnInit() {
    this.userName = await this.storage.get("loginUsername");
    this.callNecessaryApi();
    if (this.userName) {
      this.hello = this.userName;
      this.showlogout = this.userName;
      this.showlogout = "Logout";
    } else {
      this.hello = "Hello sign in";
      this.showlogout = "";
    }
  }

  scannerclick() {
    this.barcodeScanner
      .scan()
      .then((barcodeData) => {
        this.startMySearchThread().then((truthy) => {
          if (barcodeData.text == "" || barcodeData.text == null) {
            this.showToast("Not Properly Scanned");
          } else {
            this.router.navigate(["search", { scannedData: barcodeData.text }]);
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  }
  showToast(arg0: string) {
    throw new Error("Method not implemented.");
  }

  searchClick() {
    this.startMySearchThread().then((truthy) => {
      this.router.navigate(["search", { scannedData: "" }]);
    });
  }

  startMyThread() {
    return new Promise((resolve, reject) => {
      //pretend a long-running task
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  }

  startMySearchThread() {
    return new Promise((resolve, reject) => {
      //pretend a long-running task
      setTimeout(() => {
        resolve(true);
      }, 10);
    });
  }

  cartClick() {
    if (this.cartcount == 0) {
      this.presentAlert("Continue Shopping", "Your Cart is Empty");
    } else {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["cart"]);
      });
    }
  }

  async presentAlert(msg: any, submsg: any) {
    const alert = await this.alertController.create({
      message: msg,
      subHeader: submsg,
      buttons: [
        // {
        //   text: 'Cancel',
        //   handler: data => {
        //     console.log('Cancel clicked');
        //   }
        // },
        {
          text: "ok",
          handler: (data) => {
            // console.log('Saved clicked');
          },
        },
      ],
    });
    await alert.present();
  }

  loginnavigate() {
    if (this.hello == "Hello sign in") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["login"]);
      });
    }
  }

  redirectCart() {
    if (this.isGuest == "true") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["cart"]);
      });
    } else if (this.isGuest == "false") {
      this.cartClick();
    } else if (this.isGuest == undefined) {
      this.cartClick();
    }
  }

  orderhistory() {
    if (this.isGuest == "true") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["login"]);
      });
    } else if (this.isGuest == "false") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["orderhistory"]);
      });
    } else if (this.isGuest == undefined) {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["orderhistory"]);
      });
    }
  }

  menuItemClick(itemname) {
    if (itemname == "Home") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["home"]);
      });
    } else if (itemname == "Weekly Deals") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["weekly-special", { whichWeekly: "" }]);
      });
    } else if (itemname == "Best Seller") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["bestseller"]);
      });
    } else if (itemname == "Customer Service") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["customer-services"]);
      });
    } else if (itemname == "Shop by Groceries") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["category"]);
      });
    } 
    
    else if (itemname == "Notification") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["notification"]);
      });
    } else if (itemname == "Reorder List") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["wishlist"]);
      });
    } else if (itemname == "Feedback") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["feedback"]);
      });
    } else if (itemname == "Gift Card") {
      this.startMyThread().then((truthy) => {
        this.router.navigate(["giftcard"]);
      });
    } else if (itemname == "Do It Rewards Card") {
      // this.startMyThread().then(truthy => {
      //   if (this.AccountType == "Credit") {
      //     this.router.navigate(['own-card']);
      //   } else {
      //     this.router.navigate(['card']);
      //   }
      // });
    } else if (itemname == "logout") {
      this.router.navigate(["login"], { replaceUrl: true });
      this.callLogoutApi();
    } else if (itemname == "Logout" || itemname == "Login") {
      this.storage.set("loginUsername", null);
      this.storage.set("loginId", null);

      if (itemname == "Login") {
        this.storage.clear();

        this.startMyThread().then((truthy) => {
          this.router.navigate(["login"], { replaceUrl: true });
        });
      }
    }
  }
  callLogoutApi() {
    this.storage.clear();
  }
}
