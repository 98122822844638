import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';;

@Component({
  selector: 'app-email-form-modal',
  templateUrl: './email-form-modal.page.html',
  styleUrls: ['./email-form-modal.page.scss'],
})
export class EmailFormModalPage  {

  emailForm: FormGroup;

  constructor(private ModalController: ModalController,private fb: FormBuilder) {}

// ngOnInit() {
//   this.emailForm = this.fb.group({
//     name: ['', Validators.required],
//     email: ['', [Validators.required, Validators.email]],
//     phone: ['', [Validators.required, Validators.pattern('^[0-9]{7}$')]] // Validating a 10-digit phone number
//   });
// }

ngOnInit() {
  this.emailForm = this.fb.group({
    name: ['', Validators.required],
    // email: ['', [Validators.required, Validators.email]],
    email: ['', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$') // Email validation pattern
  ]],
    phone: ['', [Validators.required, Validators.pattern('^[0-9]{7}$')]] // Validating a 10-digit phone number
  });
}

onSubmit() {
  if (this.emailForm.valid) {
    // Logic for sending email
    console.log('Form Data:', this.emailForm.value);
  }
}
  // Method to send email
  sendEmail() {
    if (this.emailForm.value) {
      this.ModalController.dismiss(this.emailForm.value);  // Pass the form data back when dismissing the modal
    }
  }
dismiss() {
  this.ModalController.dismiss();
}
}
