import { Component, OnInit } from '@angular/core';
import {S3imagesService} from '../services/s3images.service';
@Component({
  selector: 'app-storereturn-exchangepolicies',
  templateUrl: './storereturn-exchangepolicies.component.html',
  styleUrls: ['./storereturn-exchangepolicies.component.scss'],
})
export class StorereturnExchangepoliciesComponent implements OnInit {
  newUrl = this.images3Service.s3url;
  constructor(private images3Service :S3imagesService) { }

  ngOnInit() {}

}
