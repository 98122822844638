// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAoXx7Y9UQdyKiyWgzKyPj2PsbEPmtb2N4",
    authDomain: "do-it-center-ionic.firebaseapp.com",
    databaseURL: "https://do-it-center-ionic.firebaseio.com",
    projectId: "do-it-center-ionic",
    storageBucket: "do-it-center-ionic.appspot.com",
    messagingSenderId: "567910207904",
    appId: "1:567910207904:web:55c5b25bb423de10f694c5",
    vapidKey: 'BL6wV2mfEkOEifIDLSED54xuhE0vmuTPdsYrHTm60t4iAq2eKt21-dFZRfEhuE6AJauy22sGD4JKChiIqKNafVg'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
