import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { IonicModule } from "@ionic/angular";




@NgModule({
    imports: [IonicModule],
    declarations:[FooterComponent,NavbarComponent],
    exports:[FooterComponent, NavbarComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class ComponentsModule{}