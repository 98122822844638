import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WeeklyService } from '../services/weekly.service';

import { Storage } from '@ionic/storage';
interface OfferData {
  offerNo: string;
  unit:string,
  priority: number;
  Starting_Date: any;
  Ending_Date: any;
}

@Component({
  selector: 'app-modal-sell-unit',
  templateUrl: './modal-sell-unit.component.html',
  styleUrls: ['./modal-sell-unit.component.scss'],
})
export class ModalSellUnitComponent implements OnInit {

  @Input() codeno: any;
  @Input() unit: any;
  @Input() price: any;
  messurunit: any;
  offerDetails: any;

  SelectedPrdUnit: any
  SelectedUnit: any;
  allKgQtyData: any;
  selectedQuantity: any;
  logincheck: any;
  seletedsellunit:any;
 
  constructor(private modalController: ModalController, public storage: Storage, private movieService: WeeklyService,) { }
  
 
  ngOnInit() {
    this.callApi();
    this.getKgQuantity();
    console.log("this.seletedsellunitthis.seletedsellunit", this.seletedsellunit); 
  }


  getKgQuantity() {
    this.allKgQtyData = this.movieService.getKgQuantity();
    this.allKgQtyData.subscribe(data => {
      this.allKgQtyData = data;
      // this.hideLoader();
    });
  }

  callApi() {
    const _this = this;

    console.log('API call with otherData:', this.codeno);
    console.log('API OOOOO PPPPPP', this.unit);
    console.log('API OOOOO PPPPPP', this.price);

    let externalData = [
        { unit: this.unit, price: this.price }
    ];

    let finalstring = "contains(No,'" + this.codeno + "')";
  
    this.storage.get('loginUsername').then(function (val) {
        _this.logincheck = val;
 
        // Set the filter condition based on the login check
        const offerNoCondition = _this.logincheck === 'Guest'
            ? (item) => item.offerNo == 'RMG000006'
            : (item) => item.offerNo !== 'RMG000006';

        console.log("offerNoCondition inside then:", offerNoCondition);

        // Fetch measure unit data within the then block to ensure the condition is set
        _this.messurunit = _this.movieService.getmessureunit(finalstring);
        _this.messurunit.subscribe(async data => {
            let internalData = data.value.map(item => ({
                unit: item.Unit_of_Measure,
                offerNo: item.Offer_No,
                status: item.Status,
                price: item.Offer_Price_Including_VAT
            })).filter(item => item.status === 'Enabled' && offerNoCondition(item));

            console.log("Internal Data:", internalData);
            console.log("Original External Data:", externalData);

            // Group data by Unit_of_Measure
            const groupedData = internalData.reduce((acc, item) => {
                acc[item.unit] = acc[item.unit] || [];
                acc[item.unit].push(item);
                return acc;
            }, {});

            console.log("Grouped Data by Unit:", groupedData);

            // Extract units with more than one enabled entry
            const unitsWithMultipleEntries = Object.keys(groupedData).filter(unit => groupedData[unit].length > 1);
            console.log("Units with multiple enabled entries:", unitsWithMultipleEntries);

            // Extract Offer_Nos for these units
            const offerDetailunit = unitsWithMultipleEntries.reduce((acc, unit) => {
                // Concatenate the current accumulator with the new array of objects
                return acc.concat(groupedData[unit].map(item => ({
                    offerNo: item.offerNo,
                    unit: unit  // Add the unit to each object
                })));
            }, []);

            console.log("Offer Numbers and Units to check priorities:", offerDetailunit);

            // Filter external data to only include items not present in internal data
            const filteredExternalData = externalData.filter(externalItem => {
                return !internalData.some(internalItem => internalItem.unit === externalItem.unit);
            });

            console.log("Filtered External Data:", filteredExternalData);

            // Merge internal data with the filtered external data
            _this.offerDetails = internalData.concat(filteredExternalData);
            console.log("Final Offer Details:", _this.offerDetails);

            _this.findHighestPriorityOffer(offerDetailunit).then(highestPriorityData => {
                if (highestPriorityData) {
                    console.log("Data for the highest priority offer number:", highestPriorityData);

                    const matchedOffers = getMatchedAndSpecialOffers(highestPriorityData, _this.offerDetails);
                    _this.offerDetails = matchedOffers;
                    console.log("Filtered Offers:", matchedOffers);
                } else {
                    console.log("No valid data found or an error occurred.");
                }
            });
        });
    });

    function getMatchedAndSpecialOffers(highestPriorityData, finalArray) {
        const specialUnits = ['EA'];  // This list can be expanded as needed

        // Filter the final array to find the matching offer number and special units
        const result = finalArray.filter(item =>
            item.offerNo === highestPriorityData.offerNo || specialUnits.includes(item.unit)
        );
        return result;
    }
}






async findHighestPriorityOffer(offerDetails: { offerNo: any, unit: string }[]): Promise<OfferData | null> {

  console.log("LLLLLLLLLLLLLLL", offerDetails);
  try {
      const results: (OfferData | null)[] = await Promise.all(offerDetails.map(offerDetail => this.fetchDiscountAndPriority.call(this, offerDetail)));
      const validResults: OfferData[] = results.filter(result => result !== null) as OfferData[];
      console.log("validResults:", validResults);

      if (validResults.length === 0) {
          console.log("No valid results were obtained.");
          return null;
      }

      const highestPriorityResult = validResults.reduce((max, current) => current.priority > max.priority ? current : max, validResults[0]);
      console.log("Highest Priority Data:", highestPriorityResult);
      return highestPriorityResult; // Return all data for the highest priority offer
  } catch (error) {
      console.error("Error processing priorities:", error);
      return null;  // Handle the error as required
  }
}     


async fetchDiscountAndPriority(offerDetail: { offerNo: any, unit: string }) {

console.log("OPOOPPPPPPPPPPPPP", offerDetail);
return new Promise((resolve, reject) => {  
    const discountCheck = this.movieService.matchdiscount(offerDetail.offerNo);
    discountCheck.subscribe(data => {
        console.log(`Data for ${offerDetail.offerNo}:`, data.value[0]);
        if (data.value[0] && data.value[0].hasOwnProperty('Priority')) {
            resolve({
                offerNo: offerDetail.offerNo,
                unit: offerDetail.unit,
                priority: data.value[0].Priority,
                Starting_Date: data.value[0].Starting_Date,
                Ending_Date: data.value[0].Ending_Date
            });
        } else {   
            resolve(null);
        }
    }, error => {
        console.error(`Error fetching data for ${offerDetail.offerNo}:`, error);
        reject(error);
    });
});  
}


 





dismissModal(event: any) {


  this.SelectedUnit = event.detail.value.unit;
  console.log("this.SelectedPrdUnitthis.SelectedPrdUnit", event.detail.value); 

  // Dismiss the modal and pass the selected value back to the parent page

  if (this.SelectedUnit == 'KG') {

    console.log("LLLLLLLLLLLLLLLLLLLLL");
  //  this.selectedQuantity = null;
  this.SelectedUnit = event.detail.value.unit;
  //this.modalController.dismiss(event.detail.value);
  } else {

    const selectedUnittt = this.SelectedPrdUnit;

    const selectedPrice = selectedUnittt.price ? selectedUnittt.price : selectedUnittt.regularprice;
  
    console.log("selectedPriceselectedPrice", selectedPrice);
   
    this.selectedQuantity = null;
     const dataToSend = {
    unit: event.detail.value.unit,
    price: selectedPrice
  };
    this.modalController.dismiss(dataToSend);
  }
 


}


kgUnitfilter(item: any) {

 
  const selectedUnittt = this.SelectedPrdUnit;

    const selectedPrice = selectedUnittt.price ? selectedUnittt.price : selectedUnittt.regularprice;
   
    this.selectedQuantity = null;
     const dataToSend = {
    unit: selectedUnittt.unit,
    price: selectedPrice,
    grm:item
  };
  this.modalController.dismiss(dataToSend);
}

// confirmSelection() {
//   const dataToSend = {
//     unit: this.SelectedUnit,
//     quantity: this.selectedQuantity
//   };
//   this.modalController.dismiss(dataToSend, 'select');
// }

closeModal() {
  // Dismiss the modal without passing any data
  this.modalController.dismiss();
}

}
