import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class S3imagesService {
// public  s3url= 'https://d2s3cc6x0yl03x.cloudfront.net/ProductImages/'; //commented by priyanka
public s3url='https://media.doit.aw/ProductImages/';
  constructor() { }
}
