import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { S3imagesService } from '../../services/s3images.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  newUrl = this.images3Service.s3url;
  constructor( private router: Router,private images3Service :S3imagesService) { }

  ngOnInit() {}

  changePage(itemname){
    if (itemname == 'Weekly Deals') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['weekly-special', { whichWeekly: "" }]);
      });

    } else if (itemname == 'Customer Service') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['customer-services']);
      });
    } else if (itemname == 'Disclaimer') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['disclaimer']);
      });
    
    } else if (itemname == 'Shop by Groceries') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['category']);
      });
    

    }
    // else if (itemname == 'Privacy Policy'){
    //   this.startMyThread().then(truthly =>{
    //     this.router.navigate(['privacy-policy']);
    //   })
    // }
    else if (itemname == 'Privacy Policy'){
      this.startMyThread().then(truthy =>{
        this.router.navigate(['storereturn-exchangepolicies'])
      })
    }
  }

  startMyThread() {
    return new Promise((resolve, reject) => {
      //pretend a long-running task
      setTimeout(() => { resolve(true); }, 1000);
    });
  }
}
